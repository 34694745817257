<template>
  <div>
    <template v-if="promotionSection">
      <!-- Promotion -->
      <template
        v-if="
          sections.length && promotionSection && promotionSection.modules.length
        "
      >
        <portal :key="promotionSection.id" to="superbanner">
          <PromotionSection
            :key="promotionSection.id"
            :promotion="promotionSection.modules[0]"
          />
        </portal>
      </template>
    </template>

    <!--
      Adup Banner Section
      ONLY FOR ES
    -->
    <template v-if="adupLoaded && allowAdup">
      <client-only>
        <homepageTopContentAdES
          class="content-container content-container--spacing ads-spacing"
          query="homepage_query"
          :locale="$i18n.locale"
        />
      </client-only>
    </template>

    <HgPromotionbanner />

    <DealsSection theme="light" class="section-spacing" />

    <!--
      Adup Banner Section
      ONLY FOR ES & Mobile
    -->
    <template v-if="adupLoaded && allowAdup && isMobile">
      <client-only>
        <homepageBottomContentAdES
          query="homepage_query"
          :locale="$i18n.locale"
          class="content-container content-container--spacing ads-spacing"
        />
      </client-only>
    </template>

    <template v-if="$i18n.locale === 'de' && newsletterBannerVariant != null">
      <client-only>
        <NewsletterBannerSection
          class="content-container section-spacing newsletter-banner-container"
          :variant="newsletterBannerVariant"
        />
      </client-only>
    </template>

    <client-only>
      <CardCategorySection class="section-spacing" />
    </client-only>

    <client-only>
      <MoodSection class="section-spacing" />
    </client-only>

    <USPSection class="section-spacing" />

    <TrustPilotSection class="section-spacing" />
  </div>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration';
import { mapState } from 'vuex';
import ContentPageMixin from '@/mixins/ContentPageMixin';
import KameleoonMixin from '@/mixins/KameleoonMixin';
import allKameleoonFeatureVariablesQuery from '@/apollo/queries/kameleoon/allKameleoonFeatureVariables';

import DealsSection from '@/components/sections/DealsSection';
import MoodSection from '~/components/sections/MoodSection.vue';
import PromotionSection from '@/components/content/sections/PromotionSection';
import TrustPilotSection from '@/components/sections/TrustPilotSection.vue';
import TagSectionBanner from '@/components/ads/adup/TagSectionBanner.vue';
import homepageTopContentAdES from '@/components/ads/adup/ES/homepage/homepageTopContentAdES.vue';
import homepageBottomContentAdES from '@/components/ads/adup/ES/homepage/homepageBottomContentAdES.vue';
import CardCategorySection from '@/components/sections/CardCategorySection.vue';
import USPSection from '@/components/sections/USPSection.vue';
import AffiliateSection from '@/components/sections/AffiliateSection.vue';
import NewsletterBannerSection from '@/components/content/sections/NewsletterBannerSection.vue';

export default {
  components: {
    DealsSection,
    LazyHydrate,
    PromotionSection,
    TrustPilotSection,
    TagSectionBanner,
    homepageTopContentAdES,
    homepageBottomContentAdES,
    CardCategorySection,
    MoodSection,
    USPSection,
    AffiliateSection,
    NewsletterBannerSection,
    HgPromotionbanner: () =>
      import('@/components/ads/HgAds/HgPromotionbanner.vue')
  },
  mixins: [ContentPageMixin, KameleoonMixin],
  props: {
    sections: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    ads: [],
    promotionSection: null,
    featureVariables: [],
    newsletterBannerVariant: null
  }),
  computed: {
    ...mapState({
      allowAdup: (state) => state.ads.allowAdup,
      adupLoaded: (state) => state.ads.adupLoaded,
      isMobile: (state) => state.isMobile
    })
  },
  mounted() {
    this.ads = this.sections.filter(
      (section) => section.name === 'AdupBannerSection'
    );
    this.setPromotionSection();
  },
  watch: {
    sections() {
      this.setPromotionSection();
    }
  },

  async fetch() {
    await this.loadKameleoon();
  },

  methods: {
    async loadKameleoon() {
      // Get the visitor code from the cookies
      let visitorCode = this.$cookies.get('KameleoonVisitorCode');

      if (visitorCode == null) {
        // Generate a new visitor code & save it as a cookie
        visitorCode = crypto.randomUUID();

        this.$cookies.set('KameleoonVisitorCode', visitorCode, {
          path: '/',
          maxAge: 60 * 60 * 24 * 14 // 2 weeks
        });
      }

      let result = await this.$apollo
        .query({
          query: allKameleoonFeatureVariablesQuery,
          variables: {
            visitorCode
          }
        })
        .catch((error) => {
          console.error(error);
        });

      if (result == null || result.data == null) return;

      this.featureVariables = result.data.allKameleoonFeatureVariables;

      let newsletterBannerVariable = this.featureVariables.find(
        (v) => v.featureKey == 'newsletterbanner'
      );

      if (newsletterBannerVariable != null) {
        this.newsletterBannerVariant = newsletterBannerVariable.variationKey;
      }
    },
    setPromotionSection() {
      if (this.sections) {
        const tempSection = this.sections.filter(
          (section) =>
            section.name === 'PromotionSection' &&
            this.isSectionVisible(section)
        );

        if (tempSection.length) {
          this.promotionSection = tempSection[0];
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.homepage__trustpilot {
  margin-left: auto;
  margin-right: auto;
  max-width: rem(1046);
}

.newsletter-banner-container {
  padding: 0 24px;
}

@media screen and (max-width: 940px) {
  .newsletter-banner-container {
    display: none;
  }
}
</style>
